import React from "react"
import { Box } from "grommet"
import { Link } from "../components"
const Component = ({ name, templateId, ...rest }) => {
  return (
    <Box>
      <Link to={`/templates/${templateId}/generate`}>{name}</Link>
    </Box>
  )
}

export default Component
