import Link from "./bases/link"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useIntl } from "../hooks"
import { Box, Button, Heading, Image } from "grommet"
import { Spinner, toast } from "../components"
import { reportError, redirect, fetchApi, encodeAppState } from "../utils"
const App = props => {
  const { app, name: connectionName, provider, connectionId } = props
  const { picture, name } = app
  const { formatMessage } = useIntl()
  const [testLoading, setTestLoading] = useState(false)
  const [disconnectLoading, setDisconnectLoading] = useState(false)
  const [reconnectLoading, setReconnectLoading] = useState(false)

  const [isDisconnected, setIsDisconnected] = useState(false)
  const handleDisconnect = async () => {
    setDisconnectLoading(true)
    try {
      await fetchApi(`/connections/${connectionId}`, {
        method: "DELETE",
      })
      setDisconnectLoading(false)
      setIsDisconnected(true)
      toast.success(
        formatMessage({
          id: "disconnect_success",
          defaultMessage: "The connection has been disconnected",
        })
      )
    } catch (error) {
      setDisconnectLoading(false)
      toast(error.message)
      reportError(error)
    }
  }

  const handleReconnectConnection = async () => {
    setReconnectLoading(true)
    try {
      const authenticatedUrlData = await fetchApi(
        `/authenticated-url/${provider}?app_state=${encodeAppState({
          action: "reconnect",
          payload: {
            connectionId: connectionId,
          },
        })}`
      )
      setReconnectLoading(false)
      if (
        authenticatedUrlData &&
        authenticatedUrlData.data &&
        authenticatedUrlData.data.location
      ) {
        redirect(authenticatedUrlData.data.location)
      }
    } catch (error) {
      setReconnectLoading(false)
      toast(error.message)
      reportError(error)
    }
  }
  const handleTest = async () => {
    setTestLoading(true)
    try {
      await fetchApi(`/connections/${connectionId}/status`)
      setTestLoading(false)
      toast.success(
        formatMessage({
          id: "test_success",
          defaultMessage: "The connection is active",
        })
      )
    } catch (error) {
      setTestLoading(false)
      toast(error.message)
      reportError(error)
    }
  }
  if (isDisconnected) {
    return null
  }
  return (
    <Box margin={{ bottom: "medium" }}>
      <Box
        pad="medium"
        round
        direction="row"
        gap="medium"
        align="center"
        border={{
          size: "small",
          color: "app-border",
        }}
      >
        <Box
          background="backgroud"
          border={{
            size: "medium",
            color: "app-icon-border",
          }}
          round="medium"
          pad="medium"
        >
          <Box height="3rem" width="3rem">
            <Image
              src={picture}
              fit="cover"
              fallback={`${name}-logo`}
              a11yTitle={`${name}-logo`}
            ></Image>
          </Box>
        </Box>
        <Box>
          <Link to={`/connections/${connectionId}`}>
            <Heading level="2">{connectionName}</Heading>
          </Link>
          <Box direction="row" gap="small">
            <Button
              disabled={testLoading}
              icon={testLoading ? <Spinner /> : null}
              secondary
              onClick={handleTest}
              label={formatMessage({
                id: "test",
                defaultMessage: "Test",
              })}
            ></Button>
            <Button
              disabled={reconnectLoading}
              icon={reconnectLoading ? <Spinner /> : null}
              secondary
              onClick={handleReconnectConnection}
              label={formatMessage({
                id: "reconnect",
                defaultMessage: "Reconnect",
              })}
            ></Button>
            <Button
              disabled={disconnectLoading}
              icon={disconnectLoading ? <Spinner /> : null}
              secondary
              onClick={handleDisconnect}
              label={formatMessage({
                id: "disconnect",
                defaultMessage: "Disconnect",
              })}
            ></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

App.propTypes = {
  name: PropTypes.string,
}

App.defaultProps = {
  name: ``,
}

export default App
