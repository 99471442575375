import React from "react"
import { Box, Button } from "grommet"
import { navigate } from "../utils"
import Link from "./bases/link"
const Component = ({ provider, siteId, sourceId }) => {
  const hanldeClickSource = () => {
    navigate(`/sites/${siteId}/sources/${sourceId}`)
  }
  return (
    <Box>
      <Box direction="row" gap="medium" align="baseline">
        <Button onClick={hanldeClickSource} label={provider}></Button>
        <Link to={`/sites/${siteId}/sources/${sourceId}/edit`}>Edit</Link>
      </Box>
    </Box>
  )
}

export default Component
