import React from "react"
import { Box, Text } from "grommet"
import { FormattedMessage } from "../utils"
const Component = () => {
  return (
    <Box align="center" pad="medium">
      <Text color="status-disabled">
        <FormattedMessage
          id="empty_content"
          defaultMessage="Nothing here"
        ></FormattedMessage>
      </Text>
    </Box>
  )
}

export default Component
