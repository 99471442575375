import Link from "./bases/link"
import PropTypes from "prop-types"
import React from "react"
import { Box, Heading } from "grommet"
import { FormattedMessage } from "../utils"
const App = props => {
  const { name: siteName, siteId } = props

  return (
    <Box margin={{ bottom: "medium" }}>
      <Box
        pad="medium"
        round
        direction="row"
        gap="medium"
        align="center"
        border={{
          size: "small",
          color: "app-border",
        }}
      >
        <Box>
          <Box direction="row" gap="medium" align="baseline">
            <Link to={`/sites/${siteId}`}>
              <Heading level="2">{siteName}</Heading>
            </Link>
            <Link to={`/sites/${siteId}/edit`}>
              <Heading level="3">
                <FormattedMessage
                  id="edit"
                  defaultMessage="Edit"
                ></FormattedMessage>
              </Heading>
            </Link>
          </Box>

          <Box direction="row" gap="small"></Box>
        </Box>
      </Box>
    </Box>
  )
}

App.propTypes = {
  name: PropTypes.string,
}

App.defaultProps = {
  name: ``,
}

export default App
